import { Transition } from '@headlessui/react';
import React, { ReactNode } from 'react';
import { withSize } from 'react-sizeme';
import { Animation } from './Animation';

export type TabItemProps = { content: ReactNode; animation: any };

const TabItem: React.FC<TabItemProps> = ({ content, animation }) => {
  return (
    <div className="flex">
      <Transition.Child
        enter="transition-all duration-1000 delay-300"
        enterFrom="opacity-0 translate-y-24"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all duration-1000"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 -translate-x-full"
      >
        <div className="prose prose-p:leading-snug prose-h2:text-xl prose-h3:text-base">
          {content}
        </div>
      </Transition.Child>
      <Transition.Child
        enter="transition-all duration-1000 delay-500"
        enterFrom="opacity-0 translate-y-8"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all duration-1000"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-full"
      >
        <Animation animationData={animation} />
      </Transition.Child>
    </div>
  );
};

export const TabItemWithSize = withSize({
  monitorHeight: true,
  monitorWidth: false,
  noPlaceholder: true,
})(TabItem);
