import { Tab, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { graphql, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Animation } from '../components/Animation';
import { BottomBar } from '../components/BottomBar';
import { Container } from '../components/Container';
import { Header } from '../components/Header';
import { TabItemWithSize } from '../components/TabItemWithSize';
import codingAnimation from '../lottie-animations/coding.json';
import dataAnalysisAnimation from '../lottie-animations/data-analysis.json';
import newIdeaAnimation from '../lottie-animations/new-idea.json';
import tacticsAnimation from '../lottie-animations/tatctics.json';

type HomePageData = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
};

type Content = {
  title: string;
  content: React.ReactNode;
  animation: any;
};

const items: Array<Content> = [
  {
    title: 'Warum wir das machen',
    content: (
      <>
        <p>
          Weil wir wachsen wollen. Solide, aus eigenen Kräften und gut geplant.
          Daher haben wir entschieden, dass aus der Hagenberg & Moritz GbR in
          2022 die Bitmade GmbH werden soll.
        </p>
        <p>
          Mit diesem Schritt vermitteln wir unseren Kunden, dass wir es wirklich
          ernst meinen und auch zukünftig ein zuverlässiger Partner sein
          möchten. Ein Partner, mit dem man gemeinsam wachsen und spannende
          Projekte professionell umsetzen kann.
        </p>
        <p>
          Und wir wollen Sicherheit für unsere Mitarbeiter. Für das schon
          bekannte "Core"-Team und auch für viele neue Kollegen. Das geht besser
          mit einer GmbH als mit einer GbR. Davon sind wir überzeugt.
        </p>
      </>
    ),
    animation: newIdeaAnimation,
  },
  {
    title: 'Was ändert sich für uns?',
    content: (
      <>
        <p>
          Wir werden professioneller in unser Außenkommunikation und wollen mehr
          Sichtbarkeit und Reichweite erzeugen.
        </p>
        <p>
          Und wir wollen wachsen, interne Strukturen aufbauen und Prozesse
          etablieren und verbessern. Wir wollen Rollen besetzen und neue Rollen
          entstehen lassen.
        </p>
        <p>
          Aber vor allem wollen wir: uns fokussieren auf eine
          Hauptdienstleistung.
        </p>
        <p>
          <strong>Bitmade ist eine Drupal-Agentur.</strong>
        </p>
      </>
    ),
    animation: dataAnalysisAnimation,
  },
  {
    title: 'Was ändert sich für Sie?',
    content: (
      <>
        <p>
          Erstmal nicht so viel. Klar… unser Firmenname wird sich ändern und
          somit auch Ihr Geschäftspartner in allen Dokumenten. Aber das war’s
          auch schon.
        </p>
        <p>
          Ihr bekanntes Berater- und Entwickler-Team bleibt 1:1 erhalten und
          auch die Kommunikations- und Arbeitsprozesse behalten wir bei. Bald
          werden dann auch neue Gesichter in den Meetings auftauchen und unser
          Leistungskatalog wird sich für Sie schärfen und erweitern.
        </p>
        <p>
          Laufende Projekte wickeln wir noch über die GbR ab, so dass Angebots-
          und späterer Rechnungsempfänger identisch bleiben. Neue Angebote ab
          Anfang 2022 kommen dann von der GmbH - aber erst, wenn wir beim Notar
          waren und geschäftsfähig sind.
        </p>
      </>
    ),
    animation: codingAnimation,
  },
  {
    title: 'Gründungstagebuch',
    content: (
      <>
        <h3>Juli 2022</h3>
        <p>
          Geschafft. Wir vergrößern unser Team. Viele Bewerbungen wurden gesichtet und geprüft.
          Wir haben Interviews geführt und Probeaufgaben verteilt. Und wir sind fündig geworden.
          Alex und Sait haben uns direkt beeindruckt und beim Probearbeiten schließlich überzeugt.
          Sie erweitern ab Juli unser Frontend-Team.
        </p>
        <p>
          Willkommen, Ihr beiden. Wir sind froh, dass Ihr bei uns seid.
          Euer neues MacBook ist auf dem Weg zu Euch und ein wenig Nervennahrung für die Onboarding-Zeit haben wir auch dazu gelegt.
          Jetzt kann es losgehen…
        </p>
        <h3>Juni 2022</h3>
        <p>
          Wir haben viel zu tun und unser Team muss größer werden.
          Daher haben wir Stellen als {' '}
          <a
            href="https://bitmade.de/job-drupal-frontend-developer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Drupal Frontend Developer
          </a> {' '}
          und {' '}
          <a
            href="https://bitmade.de/job-drupal-backend-developer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Drupal Backend Developer
          </a> {' '}
          ausgeschrieben.
        </p>
        <p>Mit Erfolg: wir bekommen Bewerbungen aus der ganzen Welt und führen erfolgversprechende Bewerbungsgespräche.</p>
        <p>Wir sind optimistisch: bald dürfen wir wohl nette neue Kollegen begrüßen und ins Team integrieren.</p>
        <h3>Mai 2022</h3>
        <p>
          Die laufenden GbR-Projekte werden schrittweise in die GmbH übernommen. Für unsere Kunden soll das ohne Mehraufwand
          ablaufen. Also machen wir die GmbH einfach zum Lieferant der GbR und produzieren mit dem im letzten Monat
          übernommenen Mitarbeiter-Team in gewohnter Qualität weiter.
        </p>
        <p>
          Darüber freuen wir uns sehr: die GmbH hat erste eigene Projekte akquiriert und wird so langsam zu einer „echten“ Firma.
        </p>
        <p>
          Gute Nachricht für unsere Bestandskunden: wir haben die aktuellen GbR-Stundensätze eingefroren bis Ende 2021.
          Damit behalten diese für neue Beauftragungen bis einschl. 31.12.2021 ihre Gültigkeit.
        </p>
        <h3>April 2022</h3>
        <p>
          Unsere laufenden Projekte werden derzeit von der GbR verwaltet und umgesetzt. Das ist klar, denn deren Projektbeginn
          lag noch in 2021 und damit vor unserem GmbH-Start. Damit der Umstieg erfolgen kann, übernehmen wir zu Beginn des
          Monats die GbR-Mitarbeiter in die GmbH. Selbstverständlich ohne Einbußen für das Team.
        </p>
        <p>
          Besser noch: wir haben unsere {' '}
          <a
            href="https://bitmade.de/karriere"
            target="_blank"
            rel="noopener noreferrer"
          >
            Joker-Tage
          </a> {' '}
          eingeführt. Das sind freie Tage, die ein Mitarbeiter nicht beantragen muss,
          sondern nach eigenem Ermessen flexibel nutzen kann. Die gab es beim Wechsel von GbR zu GmbH noch
          zum regulären Urlaub mit dazu.
        </p>
        <p>Wenn sich das bewährt, bieten wir das zukünftig auch neuen Kollegen an.</p>
        <h3>März 2022</h3>
        <p>
          Wann werden wir unser „i.G.“ im Namen los? Wir warten den halben Monat
          und dann ist es soweit. Unter HRB 33356 sind wir ab 15.03.2022 im
          Handelsregister eingetragen. Damit ist die Gründung vollzogen und wird
          sind GmbH (ja, wir haben das gefeiert).
        </p>
        <p>
          Leider gibt gibt es die Domain „bitmade.ist.gmbh“ nicht, aber
          eigentlich macht das nichts, denn das nächste interne Projekt läuft
          bereits:{' '}
          <a
            href="https://bitmade.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            bitmade.de
          </a>{' '}
          muss zu unserer GmbH-Website werden.
        </p>
        <p>Unser wichtigstes Thema in diesem Monat ist: unsere Mitarbeiter.</p>
        <h3>Februar 2022</h3>
        <p>
          Es gibt wohl kein Zurück mehr… und das ist gut so. Jetzt sind wir GmbH
          i.G. und das fühlt sich gut an. Während wir auf die Eintragung ins
          Handelsregister warten, starten wir unser internes PMO.
        </p>
        <p>
          Aktuelle Projekte sind: Prozesse und Tools. Damit folgen wir unserem
          obersten GmbH-Ziel „mehr Qualität durch bessere Strukturen“. Und was
          uns am meisten freut: wir führen erste Gespräche mit neuen Kunden.
        </p>
        <h3>Januar 2022</h3>
        <p>
          Mit dem neuen Jahr wird es nun offiziell. Wir waren beim Notar und
          haben die Beurkundung veranlasst. Der Prozess läuft und wir sind
          hoffentlich bald im Handelsregister eingetragen. So lange wollten wir
          aber nicht warten und haben die Gründung schon mal gebührend in der{' '}
          <a
            href="https://nelson-mueller.de/pages/schote"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schote in Essen
          </a>{' '}
          gefeiert.
        </p>
        <h3>Dezember 2021</h3>
        <p>
          Wir freuen uns auf die besinnlichen Tage. Mit einer letzten
          Weihnachtsfeier als GbR verabschieden wir uns von der schönen
          „Gründerzeit“ und schauen gespannt auf das, was nun kommt.
        </p>
        <p>Und wir essen Kekse… viele Kekse…</p>
        <h3>November 2021</h3>
        <p>
          Dieser Monat ist dem rechtlichen Teil gewidmet: wir haben eine Satzung
          ausgearbeitet, neue Geschäftsführerverträge erstellt und schauen uns
          derzeit die Mitarbeiterverträge an.
        </p>
        <h3>Oktober 2021</h3>
        <p>
          Nichts ist erfolgreich ohne gute Planung im Vorfeld. Daher wird es nun
          konkret: unsere Aufbauorganisation entsteht. Unumgänglich, wenn man
          später Prozesse standardisieren und dokumentieren möchte.
        </p>
        <h3>September 2021</h3>
        <p>
          Unser Portfolio nimmt immer mehr Gestalt an und wir denken jetzt über
          Prozesse, verbesserte Kundenansprache und Leistungen nach.
        </p>
        <p>Wir wollen bei Drupal bleiben…</p>
        <h3>August 2021</h3>
        <p>
          So langsam wissen wir, wo es hingehen soll: nicht weg vom aktuellen
          Leistungsspektrum der GbR, sondern verstärkt hin zu Drupal. Mit einem
          geschärften Angebot.
        </p>
        <p>
          Wir haben auch erkannt, dass wir Unterstützung im Management brauchen,
          um alle Rollen voll auszufüllen. Also suchen wir…
        </p>
        <h3>Sommer 2021</h3>
        <p>
          Wir sind im Home-Office und erkennen die Chancen und Möglichkeiten
          einer GmbH, der damit verbundenen Außenwirkung und dem Bekenntnis zum
          gemeinsam Ziel. Das wollen wir vermitteln, und zwar unseren Kunden und
          auch den Mitarbeitern. Also legen wir los…
        </p>
      </>
    ),
    animation: tacticsAnimation,
  },
];

export default function Home({ data }: PageProps<HomePageData>) {
  const [height, setHeight] = useState(500);
  const breakpoints = useBreakpoint();

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
      </Helmet>
      <Header />
      <main className="py-16 overflow-hidden">
        <Container>
          {breakpoints.lg ? (
            <Tab.Group as="div">
              <Tab.List className="grid grid-cols-4 gap-x-4 mb-24">
                {items.map(({ title }, index) => (
                  <Tab as={Fragment} key={index}>
                    {({ selected }) => (
                      <div
                        className={clsx(
                          'relative pb-6 font-bold text-base cursor-pointer transition-all duration-500 after:absolute after:bottom-0 after:left-0 after:right-0 after:bg-sky after:transition-all outline-none focus:outline-none',
                          {
                            'after:h-0.5 opacity-40 hover:opacity-80':
                              !selected,
                            'after:h-1 opacity-100 cursor-default': selected,
                          }
                        )}
                      >
                        {title}
                      </div>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels
                className="relative transition-all duration-1000"
                style={{ height: `${height}px` }}
              >
                {({ selectedIndex }) =>
                  items.map(({ content, animation }, index) => (
                    <Tab.Panel
                      key={index}
                      static
                      className={clsx(
                        'absolute inset-0',
                        selectedIndex === index ? 'z-10' : 'z-0'
                      )}
                    >
                      {({ selected }) => (
                        <Transition show={selected}>
                          <TabItemWithSize
                            content={content}
                            animation={animation}
                            onSize={(size) => {
                              if (selected && size.height && size.height > 0) {
                                setHeight(size.height);
                              }
                            }}
                          />
                        </Transition>
                      )}
                    </Tab.Panel>
                  ))
                }
              </Tab.Panels>
            </Tab.Group>
          ) : (
            items.map(({ title, content, animation }, index) => (
              <div
                key={title}
                className={index < items.length - 1 ? 'mb-8' : 'mb-0'}
              >
                <div className="prose prose-p:leading-snug prose-h2:text-xl prose-h3:text-base">
                  <Animation animationData={animation} />
                  <h2>{title}</h2>
                  {content}
                </div>
              </div>
            ))
          )}
        </Container>
      </main>
      <BottomBar />
    </>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
