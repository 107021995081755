import React from 'react';
// @ts-ignore
import logo from '../assets/logo.svg';
import { Container } from './Container';

export const Header = () => {
  return (
    <header className="relative min-h-[80%] lg:min-h-screen bg-gradient-to-b from-lavendar to-night pt-6 pb-[72px] text-white lg:pt-24 lg:pb-[88px]">
      <Container>
        <div className="lg:flex">
          <div className="lg:flex-shrink-0 lg:mr-9">
            <img
              src={logo}
              alt="Bitmade Logo"
              className="block w-24 mb-12 lg:w-32"
            />
          </div>
          <div className="max-w-2xl">
            <h1 className="font-bold mb-6 lg:mb-12">
              <small className="block text-xl text-white/50 lg:text-4xl lg:mb-3">
                2022 verändert alles
              </small>
              <span className="text-3xl lg:text-6xl">Bitmade wird GmbH</span>
            </h1>

            <p className="mb-4 text-base lg:text-xl">
              Was macht man im Lockdown, wenn Freizeitaktivitäten eingeschränkt
              oder verboten sind? Man redet. In Video-Calls. Zoom sei Dank.
            </p>
            <p className="mb-4 text-base lg:text-xl">
              Und plötzlich hat man Zeit. Viel Zeit. Ohne Fahrzeiten zum Büro.
              Und ohne Aktivitäten außerhalb der Wohnung.
            </p>
            <p className="mb-0 text-base lg:text-xl">
              Also haben wir geredet. Und eine Idee aufgegriffen, die wir schon
              seit 1-2 Jahren mit uns rumtragen.{' '}
              <strong>
                Wachstum und Skalierbarkeit unseres kleinen Unternehmens. Die
                Umwandlung in eine GmbH.
              </strong>
            </p>
          </div>
        </div>
      </Container>
      <div className="absolute left-0 bottom-6 right-0 lg:bottom-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44.828"
          height="24"
          viewBox="0 0 44.828 24"
          className="block mx-auto text-white/50"
        >
          <g transform="translate(-1.586 -11.586)">
            <path
              d="M44.707,11.869a1.025,1.025,0,0,0-1.414,0L24,30.519,4.707,11.869a1.025,1.025,0,0,0-1.414,0L1.879,13.236a.944.944,0,0,0,0,1.367L23.293,35.3a1.025,1.025,0,0,0,1.414,0L46.121,14.6a.944.944,0,0,0,0-1.367Z"
              transform="translate(0 0)"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>
    </header>
  );
};
