import { useLottie } from 'lottie-react';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type AnimationProps = {
  animationData: object;
};

export const Animation: React.FC<AnimationProps> = ({ animationData }) => {
  const { ref, inView } = useInView({});
  const { View, pause, play } = useLottie({
    animationData,
    autoplay: false,
  });

  useEffect(() => {
    if (inView) {
      play();
    } else {
      pause();
    }
  }, [inView]);

  return <div ref={ref}>{View}</div>;
};
